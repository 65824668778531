import request from "@/utils/request-future"

export const API = {
  /**
   * @type {(params: {type?: string, active?: boolean}) => Promise<import('./data').default['AdAdPagedArrayDefault']>}
   */
  adAdIndex: (params) => {
    return request(`/ad/ads`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["AdAdForm"]) => Promise<import('./data').default['any']>}
   */
  adAdCreate: (data) => {
    return request(`/ad/ads`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['AdAdDetail']>}
   */
  adAdShow: (id) => {
    return request(`/ad/ads/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["AdAdForm"]) => Promise<import('./data').default['any']>}
   */
  adAdUpdate: (id, data) => {
    return request(`/ad/ads/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  adAdDestroy: (id) => {
    return request(`/ad/ads/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  adAdMoveUp: (id) => {
    return request(`/ad/ads/${id}/move_up`, { method: "PUT" })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['BrandAdminPagedArrayDefault']>}
   */
  adminAdminIndex: (params) => {
    return request(`/admin/admins`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["BrandAdminCreateForm"]) => Promise<import('./data').default['any']>}
   */
  adminAdminCreate: (data) => {
    return request(`/admin/admins`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['BrandAdminDefault']>}
   */
  adminAdminShow: (id) => {
    return request(`/admin/admins/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["BrandAdminUpdateForm"]) => Promise<import('./data').default['any']>}
   */
  adminAdminUpdate: (id, data) => {
    return request(`/admin/admins/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(adminId: number) => Promise<import('./data').default['any']>}
   */
  adminAdminResetPassword: (adminId) => {
    return request(`/admin/admins/${adminId}/reset_password`, {
      method: "PATCH",
    })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['PermissionRoleList'][]>}
   */
  adminRoleList: (params) => {
    return request(`/admin/roles/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['PermissionRolePagedArrayDefault']>}
   */
  adminRoleIndex: (params) => {
    return request(`/admin/roles`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["PermissionRoleForm"]) => Promise<import('./data').default['any']>}
   */
  adminRoleCreate: (data) => {
    return request(`/admin/roles`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['PermissionRoleDetail']>}
   */
  adminRoleShow: (id) => {
    return request(`/admin/roles/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["PermissionRoleForm"]) => Promise<import('./data').default['any']>}
   */
  adminRoleUpdate: (id, data) => {
    return request(`/admin/roles/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  adminRoleDestroy: (id) => {
    return request(`/admin/roles/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(signedId: string) => Promise<import('./data').default['AttachmentDefault']>}
   */
  attachmentShow: (signedId) => {
    return request(`/attachments/${signedId}`, { method: "GET" })
  },

  /**
   * @type {() => Promise<import('./data').default['BrandBookingSettingDefault']>}
   */
  brandBookingSettingShow: () => {
    return request(`/booking_setting`, { method: "GET" })
  },

  /**
   * @type {(data?: definitions["BrandBookingSettingDefault"]) => Promise<import('./data').default['any']>}
   */
  brandBookingSettingUpdate: (data) => {
    return request(`/booking_setting`, { method: "PUT", data })
  },

  /**
   * @type {() => Promise<import('./data').default['BrandBrandBrandDetail']>}
   */
  brandBrandShow: () => {
    return request(`/brand`, { method: "GET" })
  },

  /**
   * @type {(data?: definitions["BrandBrandBrandForm"]) => Promise<import('./data').default['any']>}
   */
  brandBrandUpdate: (data) => {
    return request(`/brand`, { method: "PUT", data })
  },

  /**
   * @type {() => Promise<import('./data').default['BrandCommunitySettingForm']>}
   */
  brandCommunitySettingShow: () => {
    return request(`/community_setting`, { method: "GET" })
  },

  /**
   * @type {(data?: definitions["BrandCommunitySettingForm"]) => Promise<import('./data').default['BrandCommunitySettingForm']>}
   */
  brandCommunitySettingUpdate: (data) => {
    return request(`/community_setting`, { method: "PUT", data })
  },

  /**
   * @type {(params: {type: string, duration: string}) => Promise<import('./data').default['LeaderboardLeaderboardDefault']>}
   */
  brandLeaderboardShow: (params) => {
    return request(`/leaderboard`, { method: "GET", params })
  },

  /**
   * @type {(params: {type: string, duration: string}) => Promise<import('./data').default['any']>}
   */
  brandLeaderboardRefresh: (params) => {
    return request(`/leaderboard`, { method: "PUT", params })
  },

  /**
   * @type {() => Promise<import('./data').default['BrandAdminWithGrantedFeatures']>}
   */
  generalCurrentAdmin: () => {
    return request(`/current_admin`, { method: "GET" })
  },

  /**
   * @type {(campId: number, params: {state: string}) => Promise<import('./data').default['CampBookingPagedArrayDefault']>}
   */
  campBookingIndex: (campId, params) => {
    return request(`/camp/camps/${campId}/bookings`, { method: "GET", params })
  },

  /**
   * @type {(campId: number, data?: definitions["CampBookingForm"]) => Promise<import('./data').default['any']>}
   */
  campBookingCreate: (campId, data) => {
    return request(`/camp/camps/${campId}/bookings`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  campBookingCancel: (id) => {
    return request(`/camp/bookings/${id}/cancel`, { method: "DELETE" })
  },

  /**
   * @type {(params: {productId?: number, state?: string}) => Promise<import('./data').default['CampCampPagedArrayDefault']>}
   */
  campCampIndex: (params) => {
    return request(`/camp/camps`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["CampCampForm"]) => Promise<import('./data').default['any']>}
   */
  campCampCreate: (data) => {
    return request(`/camp/camps`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CampCampDefault']>}
   */
  campCampShow: (id) => {
    return request(`/camp/camps/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CampCampForm"]) => Promise<import('./data').default['any']>}
   */
  campCampUpdate: (id, data) => {
    return request(`/camp/camps/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CampCourseDefault'][]>}
   */
  campCampCourses: (id) => {
    return request(`/camp/camps/${id}/courses`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CampCampUpdateCoursesForm"]) => Promise<import('./data').default['any']>}
   */
  campCampUpdateCourses: (id, data) => {
    return request(`/camp/camps/${id}/update_courses`, { method: "PUT", data })
  },

  /**
   * @type {(id: number, data?: definitions["CampCampPostponeForm"]) => Promise<import('./data').default['CampCampDefault']>}
   */
  campCampPostpone: (id, data) => {
    return request(`/camp/camps/${id}/postpone`, { method: "PUT", data })
  },

  /**
   * @type {(id: number, data?: definitions["CampCampDuplicateForm"]) => Promise<import('./data').default['CampCampDefault']>}
   */
  campCampDuplicate: (id, data) => {
    return request(`/camp/camps/${id}/duplicate`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  campCampCancel: (id) => {
    return request(`/camp/camps/${id}/cancel`, { method: "DELETE" })
  },

  /**
   * @type {(id: number, params: {memberId: number}) => Promise<import('./data').default['MobileCouponMemberCouponDefault'][]>}
   */
  campCampCoupons: (id, params) => {
    return request(`/camp/camps/${id}/coupons`, { method: "GET", params })
  },

  /**
   * @type {() => Promise<import('./data').default['CoachCategoryPagedArrayDefault']>}
   */
  coachCategoryIndex: () => {
    return request(`/coach/categories`, { method: "GET" })
  },

  /**
   * @type {(data?: definitions["CoachCategoryForm"]) => Promise<import('./data').default['any']>}
   */
  coachCategoryCreate: (data) => {
    return request(`/coach/categories`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CoachCategoryDefault']>}
   */
  coachCategoryShow: (id) => {
    return request(`/coach/categories/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CoachCategoryForm"]) => Promise<import('./data').default['any']>}
   */
  coachCategoryUpdate: (id, data) => {
    return request(`/coach/categories/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  coachCategoryDestroy: (id) => {
    return request(`/coach/categories/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {query?: string, levelId?: number, active?: boolean}) => Promise<import('./data').default['CoachCoachPagedArrayDefault']>}
   */
  coachCoachIndex: (params) => {
    return request(`/coach/coaches`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["CoachCoachCreateForm"]) => Promise<import('./data').default['any']>}
   */
  coachCoachCreate: (data) => {
    return request(`/coach/coaches`, { method: "POST", data })
  },

  /**
   * @type {(params: {query?: string, levelId?: number}) => Promise<import('./data').default['CoachCoachList'][]>}
   */
  coachCoachList: (params) => {
    return request(`/coach/coaches/list`, { method: "GET", params })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CoachCoachDefault']>}
   */
  coachCoachShow: (id) => {
    return request(`/coach/coaches/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CoachCoachUpdateForm"]) => Promise<import('./data').default['any']>}
   */
  coachCoachUpdate: (id, data) => {
    return request(`/coach/coaches/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  coachCoachDestroy: (id) => {
    return request(`/coach/coaches/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number, data?: definitions["CoachCoachChangeLevelForm"]) => Promise<import('./data').default['any']>}
   */
  coachCoachChangeLevel: (id, data) => {
    return request(`/coach/coaches/${id}/change_level`, { method: "PUT", data })
  },

  /**
   * @type {(params: {type?: string}) => Promise<import('./data').default['CoachCoachCoachTag'][]>}
   */
  coachCoachTags: (params) => {
    return request(`/coach/coaches/tags`, { method: "GET", params })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  coachCoachActivate: (id) => {
    return request(`/coach/coaches/${id}/activate`, { method: "PUT" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  coachCoachDeactivate: (id) => {
    return request(`/coach/coaches/${id}/deactivate`, { method: "PUT" })
  },

  /**
   * @type {(coachId: number, params: {productType?: string, from?: { [key: string]: any }, to?: { [key: string]: any }, state?: string}) => Promise<import('./data').default['CourseGroupTrainingPagedArrayDefault']>}
   */
  coachCourseIndex: (coachId, params) => {
    return request(`/coach/coaches/${coachId}/courses`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(coachId: number, params: {productType?: string}) => Promise<import('./data').default['CoachCoachCoursesSummary']>}
   */
  coachCourseSummary: (coachId, params) => {
    return request(`/coach/coaches/${coachId}/courses/summary`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(coachId: number, params: {productType?: string, state?: string, from?: { [key: string]: any }, to?: { [key: string]: any }, interval: string}) => Promise<import('./data').default['CoachCoachCoursesTrendsItem'][]>}
   */
  coachCourseTrends: (coachId, params) => {
    return request(`/coach/coaches/${coachId}/courses/trends`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {() => Promise<import('./data').default['CoachLevelPagedArrayDefault']>}
   */
  coachLevelList: () => {
    return request(`/coach/levels/list`, { method: "GET" })
  },

  /**
   * @type {() => Promise<import('./data').default['CoachLevelPagedArrayDefault']>}
   */
  coachLevelIndex: () => {
    return request(`/coach/levels`, { method: "GET" })
  },

  /**
   * @type {(data?: definitions["CoachLevelForm"]) => Promise<import('./data').default['any']>}
   */
  coachLevelCreate: (data) => {
    return request(`/coach/levels`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CoachLevelDefault']>}
   */
  coachLevelShow: (id) => {
    return request(`/coach/levels/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CoachLevelForm"]) => Promise<import('./data').default['any']>}
   */
  coachLevelUpdate: (id, data) => {
    return request(`/coach/levels/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  coachLevelDestroy: (id) => {
    return request(`/coach/levels/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(coachId: number, data?: definitions["CoachCoachProductGrantForm"]) => Promise<import('./data').default['any']>}
   */
  coachProductGrant: (coachId, data) => {
    return request(`/coach/coaches/${coachId}/products/grant`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(coachId: number, params: {usage?: string}) => Promise<import('./data').default['CoachCoachProductDefault'][]>}
   */
  coachProductIndex: (coachId, params) => {
    return request(`/coach/coaches/${coachId}/products`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(coachId: number, id: number) => Promise<import('./data').default['CoachCoachProductDefault']>}
   */
  coachProductShow: (coachId, id) => {
    return request(`/coach/coaches/${coachId}/products/${id}`, {
      method: "GET",
    })
  },

  /**
   * @type {(coachId: number, id: number, data?: definitions["CoachCoachProductForm"]) => Promise<import('./data').default['any']>}
   */
  coachProductUpdate: (coachId, id, data) => {
    return request(`/coach/coaches/${coachId}/products/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(coachId: number, id: number) => Promise<import('./data').default['any']>}
   */
  coachProductDestroy: (coachId, id) => {
    return request(`/coach/coaches/${coachId}/products/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {(params: {query?: string, memberId?: number}) => Promise<import('./data').default['CouponCouponList'][]>}
   */
  couponCouponList: (params) => {
    return request(`/coupon/coupons/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {active?: boolean, memberId?: number}) => Promise<import('./data').default['CouponCouponPagedArrayDefault']>}
   */
  couponCouponIndex: (params) => {
    return request(`/coupon/coupons`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["CouponCouponForm"]) => Promise<import('./data').default['any']>}
   */
  couponCouponCreate: (data) => {
    return request(`/coupon/coupons`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CouponCouponDefault']>}
   */
  couponCouponShow: (id) => {
    return request(`/coupon/coupons/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CouponCouponForm"]) => Promise<import('./data').default['any']>}
   */
  couponCouponUpdate: (id, data) => {
    return request(`/coupon/coupons/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  couponCouponDestroy: (id) => {
    return request(`/coupon/coupons/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(data?: definitions["CouponCouponExtendValidityPeriodForm"]) => Promise<import('./data').default['any']>}
   */
  couponCouponExtendValidityPeriod: (data) => {
    return request(`/coupon/coupons/extend_validity_period`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(data?: definitions["CouponCouponRevokeForm"]) => Promise<import('./data').default['any']>}
   */
  couponCouponRevoke: (data) => {
    return request(`/coupon/coupons/revoke`, { method: "POST", data })
  },

  /**
   * @type {(eventId: number) => Promise<import('./data').default['CouponEventCouponDefault'][]>}
   */
  couponEventCouponIndex: (eventId) => {
    return request(`/coupon/events/${eventId}/event_coupons`, { method: "GET" })
  },

  /**
   * @type {(eventId: number, data?: definitions["CouponEventCouponForm"]) => Promise<import('./data').default['any']>}
   */
  couponEventCouponCreate: (eventId, data) => {
    return request(`/coupon/events/${eventId}/event_coupons`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(eventId: number, id: number) => Promise<import('./data').default['CouponEventCouponDefault']>}
   */
  couponEventCouponShow: (eventId, id) => {
    return request(`/coupon/events/${eventId}/event_coupons/${id}`, {
      method: "GET",
    })
  },

  /**
   * @type {(eventId: number, id: number, data?: definitions["CouponEventCouponForm"]) => Promise<import('./data').default['any']>}
   */
  couponEventCouponUpdate: (eventId, id, data) => {
    return request(`/coupon/events/${eventId}/event_coupons/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(eventId: number, id: number) => Promise<import('./data').default['any']>}
   */
  couponEventCouponDestroy: (eventId, id) => {
    return request(`/coupon/events/${eventId}/event_coupons/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {() => Promise<import('./data').default['CouponEventDefault'][]>}
   */
  couponEventIndex: () => {
    return request(`/coupon/events`, { method: "GET" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CouponEventDefault']>}
   */
  couponEventShow: (id) => {
    return request(`/coupon/events/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CouponEventForm"]) => Promise<import('./data').default['any']>}
   */
  couponEventUpdate: (id, data) => {
    return request(`/coupon/events/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(params: {query?: string}) => Promise<import('./data').default['CouponPackageDefault'][]>}
   */
  couponPackageList: (params) => {
    return request(`/coupon/packages/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {active?: boolean, query?: string}) => Promise<import('./data').default['CouponPackagePagedArrayDefault']>}
   */
  couponPackageIndex: (params) => {
    return request(`/coupon/packages`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["CouponPackageForm"]) => Promise<import('./data').default['any']>}
   */
  couponPackageCreate: (data) => {
    return request(`/coupon/packages`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CouponPackageDefault']>}
   */
  couponPackageShow: (id) => {
    return request(`/coupon/packages/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CouponPackageForm"]) => Promise<import('./data').default['any']>}
   */
  couponPackageUpdate: (id, data) => {
    return request(`/coupon/packages/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  couponPackageDestroy: (id) => {
    return request(`/coupon/packages/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {from?: { [key: string]: any }, to?: { [key: string]: any }, state?: string, consume_type?: { [key: string]: any }}) => Promise<import('./data').default['CourseBookingPagedArrayWithCourseAndResult']>}
   */
  courseBookingIndex: (params) => {
    return request(`/course/bookings`, { method: "GET", params })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  courseBookingCheckIn: (id) => {
    return request(`/course/bookings/${id}/check_in`, { method: "PUT" })
  },

  /**
   * @type {(id: number, data?: definitions["CourseBookingGroupTrainingCancelForm"]) => Promise<import('./data').default['any']>}
   */
  courseBookingDestroy: (id, data) => {
    return request(`/course/bookings/${id}`, { method: "DELETE", data })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, productType?: string, usage?: string, provider?: string, scope?: string, for_kid?: boolean, without_ktas_workout?: boolean, query?: string}) => Promise<import('./data').default['CourseProductList'][]>}
   */
  courseProductList: (params) => {
    return request(`/course/products/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, productType?: string, usage?: string, category?: string, query?: string}) => Promise<import('./data').default['CourseProductPagedArrayDefault']>}
   */
  courseProductIndex: (params) => {
    return request(`/course/products`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["CourseProductBrandForm"]) => Promise<import('./data').default['any']>}
   */
  courseProductCreate: (data) => {
    return request(`/course/products`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CourseProductDefault']>}
   */
  courseProductShow: (id) => {
    return request(`/course/products/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CourseProductBrandForm"]) => Promise<import('./data').default['any']>}
   */
  courseProductUpdate: (id, data) => {
    return request(`/course/products/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  courseProductDestroy: (id) => {
    return request(`/course/products/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(data?: definitions["CourseProductBrandForm"]) => Promise<import('./data').default['any']>}
   */
  courseProductCreateCoachingModeProduct: (data) => {
    return request(`/course/products/create_coaching_mode_product`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  courseProductSyncInfoFromWorkout: (id) => {
    return request(`/course/products/${id}/sync_info_from_workout`, {
      method: "PUT",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  courseProductMoveUp: (id) => {
    return request(`/course/products/${id}/move_up`, { method: "PUT" })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  courseProductProviders: () => {
    return request(`/course/products/providers`, { method: "GET" })
  },

  /**
   * @type {(params: {coach_id?: number, product_id?: number, studio_id?: number}) => Promise<import('./data').default['any']>}
   */
  courseRatingOverview: (params) => {
    return request(`/course/ratings/overview`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, coach_id?: number, product_id?: number, studio_id?: number}) => Promise<import('./data').default['CourseRatingPagedArrayDefault']>}
   */
  courseRatingIndex: (params) => {
    return request(`/course/ratings`, { method: "GET", params })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CourseRatingDetail']>}
   */
  courseRatingShow: (id) => {
    return request(`/course/ratings/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CourseRatingCloseForm"]) => Promise<import('./data').default['any']>}
   */
  courseRatingClose: (id, data) => {
    return request(`/course/ratings/${id}/close`, { method: "PUT", data })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['CourseReviewPagedArrayDefault']>}
   */
  courseReviewIndex: (params) => {
    return request(`/course/reviews`, { method: "GET", params })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CourseReviewDetail']>}
   */
  courseReviewShow: (id) => {
    return request(`/course/reviews/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CourseReviewUpdateForm"]) => Promise<import('./data').default['any']>}
   */
  courseReviewUpdate: (id, data) => {
    return request(`/course/reviews/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number, data?: definitions["CourseReviewUpdateForm"]) => Promise<import('./data').default['any']>}
   */
  courseReviewPublish: (id, data) => {
    return request(`/course/reviews/${id}/publish`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  courseReviewReject: (id) => {
    return request(`/course/reviews/${id}/reject`, { method: "PUT" })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['GrantedUserPagedArrayDefault']>}
   */
  grantedUserIndex: (params) => {
    return request(`/granted_users`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["GrantedUserForm"]) => Promise<import('./data').default['any']>}
   */
  grantedUserCreate: (data) => {
    return request(`/granted_users`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['GrantedUserDefault']>}
   */
  grantedUserShow: (id) => {
    return request(`/granted_users/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["GrantedUserForm"]) => Promise<import('./data').default['any']>}
   */
  grantedUserUpdate: (id, data) => {
    return request(`/granted_users/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  grantedUserDestroy: (id) => {
    return request(`/granted_users/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(groupTrainingId: number, params: {state?: string}) => Promise<import('./data').default['CourseBookingPagedArrayGroupTraining']>}
   */
  groupTrainingBookingIndex: (groupTrainingId, params) => {
    return request(`/group_trainings/${groupTrainingId}/bookings`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(groupTrainingId: number, data?: definitions["CourseBookingGroupTrainingForm"]) => Promise<import('./data').default['any']>}
   */
  groupTrainingBookingCreate: (groupTrainingId, data) => {
    return request(`/group_trainings/${groupTrainingId}/bookings`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(groupTrainingId: number, id: number) => Promise<import('./data').default['CourseBookingGroupTraining']>}
   */
  groupTrainingBookingShow: (groupTrainingId, id) => {
    return request(`/group_trainings/${groupTrainingId}/bookings/${id}`, {
      method: "GET",
    })
  },

  /**
   * @type {(groupTrainingId: number, id: number, data?: definitions["CourseBookingGroupTrainingForm"]) => Promise<import('./data').default['any']>}
   */
  groupTrainingBookingUpdate: (groupTrainingId, id, data) => {
    return request(`/group_trainings/${groupTrainingId}/bookings/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(groupTrainingId: number, id: number, data?: definitions["CourseBookingGroupTrainingCancelForm"]) => Promise<import('./data').default['any']>}
   */
  groupTrainingBookingDestroy: (groupTrainingId, id, data) => {
    return request(`/group_trainings/${groupTrainingId}/bookings/${id}`, {
      method: "DELETE",
      data,
    })
  },

  /**
   * @type {(groupTrainingId: number, id: number) => Promise<import('./data').default['any']>}
   */
  groupTrainingBookingCheckIn: (groupTrainingId, id) => {
    return request(
      `/group_trainings/${groupTrainingId}/bookings/${id}/check_in`,
      { method: "PUT" }
    )
  },

  /**
   * @type {(params: {studioId?: number, zoneId?: number, from?: { [key: string]: any }, to?: { [key: string]: any }}) => Promise<import('./data').default['CourseGroupTrainingDefault'][]>}
   */
  groupTrainingGroupTrainingIndex: (params) => {
    return request(`/group_trainings`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["CourseGroupTrainingForm"]) => Promise<import('./data').default['any']>}
   */
  groupTrainingGroupTrainingCreate: (data) => {
    return request(`/group_trainings`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['CourseGroupTrainingDefault']>}
   */
  groupTrainingGroupTrainingShow: (id) => {
    return request(`/group_trainings/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["CourseGroupTrainingForm"]) => Promise<import('./data').default['any']>}
   */
  groupTrainingGroupTrainingUpdate: (id, data) => {
    return request(`/group_trainings/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  groupTrainingGroupTrainingDestroy: (id) => {
    return request(`/group_trainings/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number, data?: definitions["CourseGroupTrainingUpdateOutlineForm"]) => Promise<import('./data').default['any']>}
   */
  groupTrainingGroupTrainingUpdateOutline: (id, data) => {
    return request(`/group_trainings/${id}/update_outline`, {
      method: "PATCH",
      data,
    })
  },

  /**
   * @type {(data?: definitions["CourseGroupTrainingCloneScheduleForm"]) => Promise<import('./data').default['any']>}
   */
  groupTrainingGroupTrainingCloneSchedule: (data) => {
    return request(`/group_trainings/clone_schedule`, { method: "POST", data })
  },

  /**
   * @type {(data?: definitions["CourseGroupTrainingMultiUpdateForm"]) => Promise<import('./data').default['any']>}
   */
  groupTrainingGroupTrainingMultiUpdate: (data) => {
    return request(`/group_trainings/multi_update`, { method: "PUT", data })
  },

  /**
   * @type {(data?: definitions["CourseGroupTrainingPublishScheduleForm"]) => Promise<import('./data').default['any']>}
   */
  groupTrainingGroupTrainingPublishSchedule: (data) => {
    return request(`/group_trainings/publish_schedule`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(params: {zoneId?: number, from?: { [key: string]: any }, to?: { [key: string]: any }}) => Promise<import('./data').default['GroupTrainingOutlineScheduleDefault'][]>}
   */
  groupTrainingGroupTrainingOutlineSchedule: (params) => {
    return request(`/group_trainings/outline_schedule`, {
      method: "GET",
      params,
    })
  },

  /**
         * @type {(data?: {
"zone_id"?: number;
"from"?: string;
"to"?: string;
"outlines"?: ({
"product_id"?: number;
"outline_id"?: number;

})[];

}) => Promise<import('./data').default['any']>}
         */
  groupTrainingGroupTrainingBatchUpdateOutline: (data) => {
    return request(`/group_trainings/batch_update_outline`, {
      method: "PUT",
      data,
    })
  },

  /**
         * @type {(data?: {
"zone_id"?: number;
"from"?: string;
"to"?: string;

}) => Promise<import('./data').default['any']>}
         */
  groupTrainingGroupTrainingBatchCancelCourses: (data) => {
    return request(`/group_trainings/batch_cancel_courses`, {
      method: "PATCH",
      data,
    })
  },

  /**
   * @type {(params: {coachId?: number, workoutId?: number, from?: string, to?: string}) => Promise<import('./data').default['any']>}
   */
  groupTrainingGroupTrainingReviewSchedule: (params) => {
    return request(`/group_trainings/review_schedule`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {coachId?: number, workoutId?: number, from?: string, to?: string}) => Promise<import('./data').default['any']>}
   */
  groupTrainingGroupTrainingCoachStatistic: (params) => {
    return request(`/group_trainings/coach_statistic`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {workoutId?: number, coachId?: number, from?: string, to?: string}) => Promise<import('./data').default['any']>}
   */
  groupTrainingGroupTrainingWorkoutStatistic: (params) => {
    return request(`/group_trainings/workout_statistic`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {groupTrainingId: number}) => Promise<import('./data').default['any']>}
   */
  groupTrainingGroupTrainingOccupancyRate: (params) => {
    return request(`/group_trainings/course_slot_time_occupancy_rate`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(id: number, params: {memberId: number}) => Promise<import('./data').default['MobileCouponMemberCouponDefault'][]>}
   */
  groupTrainingGroupTrainingCoupons: (id, params) => {
    return request(`/group_trainings/${id}/coupons`, { method: "GET", params })
  },

  /**
   * @type {(id: number, data?: definitions["CourseGroupTrainingCancelForm"]) => Promise<import('./data').default['any']>}
   */
  groupTrainingGroupTrainingCancel: (id, data) => {
    return request(`/group_trainings/${id}/cancel`, { method: "PATCH", data })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['KtasGameList'][]>}
   */
  ktasGameList: (params) => {
    return request(`/ktas/games/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {tagName: string}) => Promise<import('./data').default['any']>}
   */
  ktasGameTags: (params) => {
    return request(`/ktas/games/tags`, { method: "GET", params })
  },

  /**
   * @type {(params: {query?: string, active?: boolean, page?: number, pageSize?: number}) => Promise<import('./data').default['KtasGamePagedArrayDefault']>}
   */
  ktasGameIndex: (params) => {
    return request(`/ktas/games`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["KtasGameForm"]) => Promise<import('./data').default['any']>}
   */
  ktasGameCreate: (data) => {
    return request(`/ktas/games`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasGameDetail']>}
   */
  ktasGameShow: (id) => {
    return request(`/ktas/games/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasGameForm"]) => Promise<import('./data').default['any']>}
   */
  ktasGameUpdate: (id, data) => {
    return request(`/ktas/games/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasGameDestroy: (id) => {
    return request(`/ktas/games/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {productId?: number, id?: number}) => Promise<import('./data').default['KtasOutlineDefault'][]>}
   */
  ktasOutlineList: (params) => {
    return request(`/ktas/outlines/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {tagName: string}) => Promise<import('./data').default['any']>}
   */
  ktasOutlineTags: (params) => {
    return request(`/ktas/outlines/tags`, { method: "GET", params })
  },

  /**
   * @type {(workoutId: number, params: {state?: string, page?: number, pageSize?: number}) => Promise<import('./data').default['KtasOutlinePagedArrayDefault']>}
   */
  ktasOutlineIndex: (workoutId, params) => {
    return request(`/ktas/workouts/${workoutId}/outlines`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(workoutId: number, data?: definitions["KtasOutlineForm"]) => Promise<import('./data').default['any']>}
   */
  ktasOutlineCreate: (workoutId, data) => {
    return request(`/ktas/workouts/${workoutId}/outlines`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasOutlineDetail']>}
   */
  ktasOutlineShow: (id) => {
    return request(`/ktas/outlines/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasOutlineForm"]) => Promise<import('./data').default['any']>}
   */
  ktasOutlineUpdate: (id, data) => {
    return request(`/ktas/outlines/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasOutlineDestroy: (id) => {
    return request(`/ktas/outlines/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasOutlineCertificate: (id) => {
    return request(`/ktas/outlines/${id}/certificate`, { method: "PUT" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasOutlineAbandon: (id) => {
    return request(`/ktas/outlines/${id}/abandon`, { method: "PUT" })
  },

  /**
   * @type {(params: {tagName: string}) => Promise<import('./data').default['any']>}
   */
  ktasSectionTags: (params) => {
    return request(`/ktas/sections/tags`, { method: "GET", params })
  },

  /**
   * @type {(outlineId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['KtasSectionPagedArrayDefault']>}
   */
  ktasSectionIndex: (outlineId, params) => {
    return request(`/ktas/outlines/${outlineId}/sections`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(outlineId: number, data?: definitions["KtasSectionForm"]) => Promise<import('./data').default['any']>}
   */
  ktasSectionCreate: (outlineId, data) => {
    return request(`/ktas/outlines/${outlineId}/sections`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasSectionDetail']>}
   */
  ktasSectionShow: (id) => {
    return request(`/ktas/sections/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasSectionForm"]) => Promise<import('./data').default['any']>}
   */
  ktasSectionUpdate: (id, data) => {
    return request(`/ktas/sections/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasSectionDestroy: (id) => {
    return request(`/ktas/sections/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['KtasTrainingMethodList'][]>}
   */
  ktasTrainingMethodList: (params) => {
    return request(`/ktas/training_methods/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {tagName: string}) => Promise<import('./data').default['any']>}
   */
  ktasTrainingMethodTags: (params) => {
    return request(`/ktas/training_methods/tags`, { method: "GET", params })
  },

  /**
   * @type {(params: {query?: string, active?: boolean, page?: number, pageSize?: number}) => Promise<import('./data').default['KtasTrainingMethodPagedArrayDefault']>}
   */
  ktasTrainingMethodIndex: (params) => {
    return request(`/ktas/training_methods`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["KtasTrainingMethodForm"]) => Promise<import('./data').default['any']>}
   */
  ktasTrainingMethodCreate: (data) => {
    return request(`/ktas/training_methods`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasTrainingMethodDetail']>}
   */
  ktasTrainingMethodShow: (id) => {
    return request(`/ktas/training_methods/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasTrainingMethodForm"]) => Promise<import('./data').default['any']>}
   */
  ktasTrainingMethodUpdate: (id, data) => {
    return request(`/ktas/training_methods/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasTrainingMethodDestroy: (id) => {
    return request(`/ktas/training_methods/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['KtasWorkoutList'][]>}
   */
  ktasWorkoutList: (params) => {
    return request(`/ktas/workouts/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {tagName: string}) => Promise<import('./data').default['any']>}
   */
  ktasWorkoutTags: (params) => {
    return request(`/ktas/workouts/tags`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number}) => Promise<import('./data').default['KtasWorkoutPagedArrayDefault']>}
   */
  ktasWorkoutIndex: (params) => {
    return request(`/ktas/workouts`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["KtasWorkoutForm"]) => Promise<import('./data').default['any']>}
   */
  ktasWorkoutCreate: (data) => {
    return request(`/ktas/workouts`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['KtasWorkoutDetail']>}
   */
  ktasWorkoutShow: (id) => {
    return request(`/ktas/workouts/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["KtasWorkoutForm"]) => Promise<import('./data').default['any']>}
   */
  ktasWorkoutUpdate: (id, data) => {
    return request(`/ktas/workouts/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  ktasWorkoutDestroy: (id) => {
    return request(`/ktas/workouts/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(memberId: number, accountId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['MembershipAccountMemberPagedArrayDefault']>}
   */
  memberAccountAccountMemberIndex: (memberId, accountId, params) => {
    return request(
      `/members/${memberId}/accounts/${accountId}/account_members`,
      { method: "GET", params }
    )
  },

  /**
   * @type {(memberId: number, accountId: number, data?: definitions["MembershipAccountMemberCreateForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountAccountMemberCreate: (memberId, accountId, data) => {
    return request(
      `/members/${memberId}/accounts/${accountId}/account_members`,
      { method: "POST", data }
    )
  },

  /**
   * @type {(memberId: number, accountId: number, data?: definitions["MembershipAccountMemberAddChildForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountAccountMemberAddChild: (memberId, accountId, data) => {
    return request(
      `/members/${memberId}/accounts/${accountId}/account_members/add_child`,
      { method: "POST", data }
    )
  },

  /**
   * @type {(memberId: number, accountId: number, id: number, data?: definitions["MembershipAccountMemberUpdateForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountAccountMemberUpdate: (memberId, accountId, id, data) => {
    return request(
      `/members/${memberId}/accounts/${accountId}/account_members/${id}`,
      { method: "PUT", data }
    )
  },

  /**
   * @type {(memberId: number, accountId: number, id: number) => Promise<import('./data').default['any']>}
   */
  memberAccountAccountMemberDestroy: (memberId, accountId, id) => {
    return request(
      `/members/${memberId}/accounts/${accountId}/account_members/${id}`,
      { method: "DELETE" }
    )
  },

  /**
   * @type {(memberId: number, params: {accountType?: string}) => Promise<import('./data').default['MembershipAccountSummary']>}
   */
  memberAccountSummary: (memberId, params) => {
    return request(`/members/${memberId}/accounts/summary`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(memberId: number, params: {accountType?: string}) => Promise<import('./data').default['MembershipAccountDefault'][]>}
   */
  memberAccountIndex: (memberId, params) => {
    return request(`/members/${memberId}/accounts`, { method: "GET", params })
  },

  /**
   * @type {(memberId: number, params: {courseId?: number, type?: string}) => Promise<import('./data').default['MembershipAccountDefault'][]>}
   */
  memberAccountGranted: (memberId, params) => {
    return request(`/members/${memberId}/accounts/granted`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(memberId: number, params: {accountId?: number}) => Promise<import('./data').default['MembershipAccountDefault'][]>}
   */
  memberAccountMergable: (memberId, params) => {
    return request(`/members/${memberId}/accounts/mergable`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(memberId: number, id: number) => Promise<import('./data').default['MembershipAccountDefault']>}
   */
  memberAccountShow: (memberId, id) => {
    return request(`/members/${memberId}/accounts/${id}`, { method: "GET" })
  },

  /**
   * @type {(memberId: number, id: number, data?: definitions["MembershipAccountPauseForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountPause: (memberId, id, data) => {
    return request(`/members/${memberId}/accounts/${id}/pause`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(memberId: number, id: number, data?: definitions["MembershipAccountPunishForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountPunish: (memberId, id, data) => {
    return request(`/members/${memberId}/accounts/${id}/punish`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(memberId: number, id: number, data?: definitions["MembershipAccountActivateForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountActivate: (memberId, id, data) => {
    return request(`/members/${memberId}/accounts/${id}/activate`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(memberId: number, id: number, data?: definitions["MembershipAccountActivateForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountResume: (memberId, id, data) => {
    return request(`/members/${memberId}/accounts/${id}/resume`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(memberId: number, id: number, data?: definitions["MembershipAccountExtendValidityPeriodForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountExtendValidityPeriod: (memberId, id, data) => {
    return request(
      `/members/${memberId}/accounts/${id}/extend_validity_period`,
      { method: "POST", data }
    )
  },

  /**
   * @type {(memberId: number, data?: definitions["MembershipAccountRechargeForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountRechargeByCard: (memberId, data) => {
    return request(`/members/${memberId}/accounts/recharge`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(memberId: number, id: number, data?: definitions["MembershipAccountRechargeForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountRecharge: (memberId, id, data) => {
    return request(`/members/${memberId}/accounts/${id}/recharge`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(memberId: number, id: number, data?: definitions["MembershipAccountConsumeForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountConsume: (memberId, id, data) => {
    return request(`/members/${memberId}/accounts/${id}/consume`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(memberId: number, data?: definitions["MembershipAccountConsumeProductWithPrepayForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountConsumeProductWithPrepay: (memberId, data) => {
    return request(
      `/members/${memberId}/accounts/consume_product_with_prepay`,
      { method: "POST", data }
    )
  },

  /**
   * @type {(memberId: number, id: number, data?: definitions["MembershipAccountTransferForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountTransfer: (memberId, id, data) => {
    return request(`/members/${memberId}/accounts/${id}/transfer`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(memberId: number, id: number, data?: definitions["MembershipAccountMergeForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountMerge: (memberId, id, data) => {
    return request(`/members/${memberId}/accounts/${id}/merge`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(memberId: number, id: number, data?: definitions["MembershipAccountChangeCoachForm"]) => Promise<import('./data').default['any']>}
   */
  memberAccountChangeCoach: (memberId, id, data) => {
    return request(`/members/${memberId}/accounts/${id}/change_coach`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(memberId: number, params: {productType?: string}) => Promise<import('./data').default['CourseBookingMemberSummary']>}
   */
  memberBookingSummary: (memberId, params) => {
    return request(`/members/${memberId}/bookings/summary`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(memberId: number, params: {productType?: string, from?: { [key: string]: any }, to?: { [key: string]: any }, interval: string}) => Promise<import('./data').default['CourseBookingMemberReportItem'][]>}
   */
  memberBookingReport: (memberId, params) => {
    return request(`/members/${memberId}/bookings/report`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(memberId: number) => Promise<import('./data').default['undefined']>}
   */
  memberBookingExport: (memberId) => {
    return request(`/members/${memberId}/bookings/export`, { method: "POST" })
  },

  /**
   * @type {(memberId: number, params: {page?: number, pageSize?: number, productType?: string, from?: { [key: string]: any }, to?: { [key: string]: any }}) => Promise<import('./data').default['CourseBookingPagedArrayWithCourseAndResult']>}
   */
  memberBookingIndex: (memberId, params) => {
    return request(`/members/${memberId}/bookings`, { method: "GET", params })
  },

  /**
   * @type {(memberId: number, id: number) => Promise<import('./data').default['CourseBookingDefault']>}
   */
  memberBookingShow: (memberId, id) => {
    return request(`/members/${memberId}/bookings/${id}`, { method: "GET" })
  },

  /**
   * @type {(memberId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['MemberCommunicationPagedArrayDefault']>}
   */
  memberCommunicationIndex: (memberId, params) => {
    return request(`/members/${memberId}/communications`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(memberId: number, data?: definitions["MemberCommunicationForm"]) => Promise<import('./data').default['any']>}
   */
  memberCommunicationCreate: (memberId, data) => {
    return request(`/members/${memberId}/communications`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(memberId: number, id: number) => Promise<import('./data').default['any']>}
   */
  memberCommunicationDestroy: (memberId, id) => {
    return request(`/members/${memberId}/communications/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {(memberId: number, params: {id?: number}) => Promise<import('./data').default['ZpkExamRecordList'][]>}
   */
  memberExamRecordList: (memberId, params) => {
    return request(`/members/${memberId}/exam_records/list`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(memberId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['ZpkExamRecordPagedArrayDefault']>}
   */
  memberExamRecordIndex: (memberId, params) => {
    return request(`/members/${memberId}/exam_records`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(memberId: number, data?: definitions["ZpkExamRecordForm"]) => Promise<import('./data').default['any']>}
   */
  memberExamRecordCreate: (memberId, data) => {
    return request(`/members/${memberId}/exam_records`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(memberId: number, id: number) => Promise<import('./data').default['ZpkExamRecordDefault']>}
   */
  memberExamRecordShow: (memberId, id) => {
    return request(`/members/${memberId}/exam_records/${id}`, { method: "GET" })
  },

  /**
   * @type {(memberId: number, id: number, data?: definitions["ZpkExamRecordForm"]) => Promise<import('./data').default['any']>}
   */
  memberExamRecordUpdate: (memberId, id, data) => {
    return request(`/members/${memberId}/exam_records/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(memberId: number, id: number) => Promise<import('./data').default['any']>}
   */
  memberExamRecordDestroy: (memberId, id) => {
    return request(`/members/${memberId}/exam_records/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {(params: {from?: string, to?: string}) => Promise<import('./data').default['MemberInvitationPagedArrayDefault']>}
   */
  memberInvitationOverview: (params) => {
    return request(`/invitations/overview`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, from?: string, to?: string, confirmed?: boolean}) => Promise<import('./data').default['MemberInvitationPagedArrayDefault']>}
   */
  memberInvitationIndex: (params) => {
    return request(`/invitations`, { method: "GET", params })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['MemberInvitationDetail']>}
   */
  memberInvitationShow: (id) => {
    return request(`/invitations/${id}`, { method: "GET" })
  },

  /**
   * @type {(memberId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['MemberMemberPagedArrayDefault']>}
   */
  memberKidIndex: (memberId, params) => {
    return request(`/members/${memberId}/kids`, { method: "GET", params })
  },

  /**
   * @type {(memberId: number, data?: definitions["MemberMemberKidForm"]) => Promise<import('./data').default['any']>}
   */
  memberKidAdd: (memberId, data) => {
    return request(`/members/${memberId}/kids`, { method: "POST", data })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string}) => Promise<import('./data').default['MemberMemberPagedArrayDefault']>}
   */
  memberMemberKids: (params) => {
    return request(`/members/kids`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["MemberMemberKidForm"]) => Promise<import('./data').default['any']>}
   */
  memberKidCreate: (data) => {
    return request(`/members/kids`, { method: "POST", data })
  },

  /**
   * @type {(memberId: number, id: number) => Promise<import('./data').default['any']>}
   */
  memberKidDestroy: (memberId, id) => {
    return request(`/members/${memberId}/kids/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(memberId: number, params: {workflowState?: string, page?: number, pageSize?: number}) => Promise<import('./data').default['CouponMemberCouponPagedArrayDefault']>}
   */
  memberMemberCouponIndex: (memberId, params) => {
    return request(`/members/${memberId}/member_coupons`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(memberId: number, data?: definitions["CouponMemberCouponForm"]) => Promise<import('./data').default['any']>}
   */
  memberMemberCouponCreate: (memberId, data) => {
    return request(`/members/${memberId}/member_coupons`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(memberId: number, id: number) => Promise<import('./data').default['any']>}
   */
  memberMemberCouponDestroy: (memberId, id) => {
    return request(`/members/${memberId}/member_coupons/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {(memberId: number, id: number, data?: definitions["CouponMemberCouponRestoreForm"]) => Promise<import('./data').default['any']>}
   */
  memberMemberCouponRestore: (memberId, id, data) => {
    return request(`/members/${memberId}/member_coupons/${id}/restore`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(params: {id?: number, userId?: number, query?: string}) => Promise<import('./data').default['MemberMemberEssential'][]>}
   */
  memberMemberList: (params) => {
    return request(`/members/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string}) => Promise<import('./data').default['MemberMemberPagedArrayDefault']>}
   */
  memberMemberIndex: (params) => {
    return request(`/members`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["MemberMemberForm"]) => Promise<import('./data').default['MemberMemberDefault']>}
   */
  memberMemberCreate: (data) => {
    return request(`/members`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['MemberMemberDefault']>}
   */
  memberMemberShow: (id) => {
    return request(`/members/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["MemberMemberForm"]) => Promise<import('./data').default['any']>}
   */
  memberMemberUpdate: (id, data) => {
    return request(`/members/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  memberMemberUnlinkWechat: (id) => {
    return request(`/members/${id}/unlink_wechat`, { method: "PUT" })
  },

  /**
   * @type {(id: number, data?: definitions["MemberMemberHofForm"]) => Promise<import('./data').default['any']>}
   */
  memberMemberUpdateHof: (id, data) => {
    return request(`/members/${id}/hof`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  memberMemberActivate: (id) => {
    return request(`/members/${id}/activate`, { method: "PUT" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  memberMemberDeactivate: (id) => {
    return request(`/members/${id}/deactivate`, { method: "PUT" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['MobileMemberInvitationPagedArrayDefault']>}
   */
  memberMemberInvitations: (id) => {
    return request(`/members/${id}/invitations`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["MemberTrainingProfileDefault"]) => Promise<import('./data').default['any']>}
   */
  memberMemberUpdateTrainingProfile: (id, data) => {
    return request(`/members/${id}/training_profile`, { method: "PUT", data })
  },

  /**
   * @type {(params: {memberType?: string, displayTagCount?: boolean}) => Promise<import('./data').default['MemberMemberMemberTag'][]>}
   */
  memberMemberTags: (params) => {
    return request(`/members/tags`, { method: "GET", params })
  },

  /**
   * @type {(memberId: number) => Promise<import('./data').default['OrderMemberSummary']>}
   */
  memberOrderSummary: (memberId) => {
    return request(`/members/${memberId}/orders/summary`, { method: "GET" })
  },

  /**
   * @type {(memberId: number, params: {from?: { [key: string]: any }, to?: { [key: string]: any }, interval: string}) => Promise<import('./data').default['OrderMemberReportItem'][]>}
   */
  memberOrderReport: (memberId, params) => {
    return request(`/members/${memberId}/orders/report`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(memberId: number, params: {page?: number, pageSize?: number, from?: { [key: string]: any }, to?: { [key: string]: any }}) => Promise<import('./data').default['OrderPagedArrayDefault']>}
   */
  memberOrderIndex: (memberId, params) => {
    return request(`/members/${memberId}/orders`, { method: "GET", params })
  },

  /**
   * @type {(memberId: number, id: number) => Promise<import('./data').default['OrderDefault']>}
   */
  memberOrderShow: (memberId, id) => {
    return request(`/members/${memberId}/orders/${id}`, { method: "GET" })
  },

  /**
   * @type {(memberId: number, accountId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['MembershipRecordPagedArrayDefault']>}
   */
  memberAccountRecordIndex: (memberId, accountId, params) => {
    return request(`/members/${memberId}/accounts/${accountId}/records`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(memberId: number, accountId: number, id: number) => Promise<import('./data').default['any']>}
   */
  memberAccountRecordDestroy: (memberId, accountId, id) => {
    return request(`/members/${memberId}/accounts/${accountId}/records/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {(cardOptionId: number) => Promise<import('./data').default['CouponCardOptionCouponDefault'][]>}
   */
  membershipCardOptionCouponIndex: (cardOptionId) => {
    return request(
      `/membership/prepay_card_options/${cardOptionId}/card_option_coupons`,
      { method: "GET" }
    )
  },

  /**
   * @type {(cardOptionId: number, data?: definitions["CouponCardOptionCouponForm"]) => Promise<import('./data').default['any']>}
   */
  membershipCardOptionCouponCreate: (cardOptionId, data) => {
    return request(
      `/membership/prepay_card_options/${cardOptionId}/card_option_coupons`,
      { method: "POST", data }
    )
  },

  /**
   * @type {(cardOptionId: number, id: number) => Promise<import('./data').default['CouponCardOptionCouponDefault']>}
   */
  membershipCardOptionCouponShow: (cardOptionId, id) => {
    return request(
      `/membership/prepay_card_options/${cardOptionId}/card_option_coupons/${id}`,
      { method: "GET" }
    )
  },

  /**
   * @type {(cardOptionId: number, id: number, data?: definitions["CouponCardOptionCouponForm"]) => Promise<import('./data').default['any']>}
   */
  membershipCardOptionCouponUpdate: (cardOptionId, id, data) => {
    return request(
      `/membership/prepay_card_options/${cardOptionId}/card_option_coupons/${id}`,
      { method: "PUT", data }
    )
  },

  /**
   * @type {(cardOptionId: number, id: number) => Promise<import('./data').default['any']>}
   */
  membershipCardOptionCouponDestroy: (cardOptionId, id) => {
    return request(
      `/membership/prepay_card_options/${cardOptionId}/card_option_coupons/${id}`,
      { method: "DELETE" }
    )
  },

  /**
   * @type {(params: {card_type?: string}) => Promise<import('./data').default['MembershipCardOptionDefault'][]>}
   */
  membershipCardOptionList: (params) => {
    return request(`/membership/cards/card_options/list`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(cardId: number, params: {active?: boolean}) => Promise<import('./data').default['MembershipCardOptionPagedArrayDefault']>}
   */
  membershipCardOptionIndex: (cardId, params) => {
    return request(`/membership/cards/${cardId}/card_options`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(cardId: number, data?: definitions["MembershipCardOptionForm"]) => Promise<import('./data').default['any']>}
   */
  membershipCardOptionCreate: (cardId, data) => {
    return request(`/membership/cards/${cardId}/card_options`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(cardId: number, id: number) => Promise<import('./data').default['MembershipCardOptionDefault']>}
   */
  membershipCardOptionShow: (cardId, id) => {
    return request(`/membership/cards/${cardId}/card_options/${id}`, {
      method: "GET",
    })
  },

  /**
   * @type {(cardId: number, id: number, data?: definitions["MembershipCardOptionForm"]) => Promise<import('./data').default['any']>}
   */
  membershipCardOptionUpdate: (cardId, id, data) => {
    return request(`/membership/cards/${cardId}/card_options/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(cardId: number, id: number) => Promise<import('./data').default['any']>}
   */
  membershipCardOptionDestroy: (cardId, id) => {
    return request(`/membership/cards/${cardId}/card_options/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {(cardId: number, id: number) => Promise<import('./data').default['any']>}
   */
  membershipCardOptionMoveUp: (cardId, id) => {
    return request(`/membership/cards/${cardId}/card_options/${id}/move_up`, {
      method: "PATCH",
    })
  },

  /**
   * @type {(cardId: number, params: {active?: boolean}) => Promise<import('./data').default['MembershipCardProductPagedArrayDefault']>}
   */
  membershipCardProductIndex: (cardId, params) => {
    return request(`/membership/cards/${cardId}/card_products`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(cardId: number, id: number) => Promise<import('./data').default['MembershipCardProductDefault']>}
   */
  membershipCardProductShow: (cardId, id) => {
    return request(`/membership/cards/${cardId}/card_products/${id}`, {
      method: "GET",
    })
  },

  /**
   * @type {(cardId: number, id: number, data?: definitions["MembershipCardProductForm"]) => Promise<import('./data').default['any']>}
   */
  membershipCardProductUpdate: (cardId, id, data) => {
    return request(`/membership/cards/${cardId}/card_products/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(params: {query?: string, include_prepay?: boolean, productType: string, cardType: string}) => Promise<import('./data').default['MembershipCardEssential'][]>}
   */
  membershipCardList: (params) => {
    return request(`/membership/cards/list`, { method: "GET", params })
  },

  /**
   * @type {(params: {cardType: string, productType: string, active?: boolean}) => Promise<import('./data').default['MembershipCardPagedArrayEssential']>}
   */
  membershipCardIndex: (params) => {
    return request(`/membership/cards`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["MembershipCardForm"]) => Promise<import('./data').default['any']>}
   */
  membershipCardCreate: (data) => {
    return request(`/membership/cards`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['MembershipCardDefault']>}
   */
  membershipCardShow: (id) => {
    return request(`/membership/cards/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["MembershipCardForm"]) => Promise<import('./data').default['any']>}
   */
  membershipCardUpdate: (id, data) => {
    return request(`/membership/cards/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  membershipCardDestroy: (id) => {
    return request(`/membership/cards/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  membershipCardMoveUp: (id) => {
    return request(`/membership/cards/${id}/move_up`, { method: "PATCH" })
  },

  /**
   * @type {(params: {active?: boolean}) => Promise<import('./data').default['MembershipCardOptionPagedArrayDefault']>}
   */
  membershipPrepayCardOptionIndex: (params) => {
    return request(`/membership/prepay_card/card_options`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(data?: definitions["MembershipCardOptionPrepayForm"]) => Promise<import('./data').default['any']>}
   */
  membershipPrepayCardOptionCreate: (data) => {
    return request(`/membership/prepay_card/card_options`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['MembershipCardOptionDefault']>}
   */
  membershipPrepayCardOptionShow: (id) => {
    return request(`/membership/prepay_card/card_options/${id}`, {
      method: "GET",
    })
  },

  /**
   * @type {(id: number, data?: definitions["MembershipCardOptionPrepayForm"]) => Promise<import('./data').default['any']>}
   */
  membershipPrepayCardOptionUpdate: (id, data) => {
    return request(`/membership/prepay_card/card_options/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  membershipPrepayCardOptionDestroy: (id) => {
    return request(`/membership/prepay_card/card_options/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  membershipPrepayCardOptionMoveUp: (id) => {
    return request(`/membership/prepay_card/card_options/${id}/move_up`, {
      method: "PUT",
    })
  },

  /**
   * @type {() => Promise<import('./data').default['MembershipPrepayCardDefault']>}
   */
  membershipPrepayCardShow: () => {
    return request(`/membership/prepay_card`, { method: "GET" })
  },

  /**
   * @type {(data?: definitions["MembershipPrepayCardForm"]) => Promise<import('./data').default['any']>}
   */
  membershipPrepayCardUpdate: (data) => {
    return request(`/membership/prepay_card`, { method: "PUT", data })
  },

  /**
   * @type {(data?: definitions["MembershipPrepayCardForm"]) => Promise<import('./data').default['any']>}
   */
  membershipPrepayCardCreate: (data) => {
    return request(`/membership/prepay_card`, { method: "POST", data })
  },

  /**
   * @type {(params: {query?: string, source_type?: string, from: string, to: string, page?: number, pageSize?: number}) => Promise<import('./data').default['BrandOperatingCouponPagedArrayDefault']>}
   */
  operatingCouponIndex: (params) => {
    return request(`/operating/coupons`, { method: "GET", params })
  },

  /**
   * @type {(couponId: number, params: {source_type?: string, from: string, to: string, page?: number, pageSize?: number}) => Promise<import('./data').default['BrandOperatingMemberCouponPagedArrayDefault']>}
   */
  operatingCouponMemberCoupons: (couponId, params) => {
    return request(`/operating/coupons/${couponId}/member_coupons`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {from: string, to: string}) => Promise<import('./data').default['any']>}
   */
  operatingGiftBookingOverview: (params) => {
    return request(`/operating/gift_bookings/overview`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {from: string, to: string, page?: number, pageSize?: number}) => Promise<import('./data').default['CourseBookingPagedArrayGiftBooking']>}
   */
  operatingGiftBookingIndex: (params) => {
    return request(`/operating/gift_bookings`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string, duration?: (any)[]}) => Promise<import('./data').default['OperatingMemberPagedArrayDefault']>}
   */
  operatingMemberNewMembers: (params) => {
    return request(`/operating/members/new_members`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string}) => Promise<import('./data').default['OperatingBookingPagedArrayDefault']>}
   */
  operatingMemberNewMemberBookings: (params) => {
    return request(`/operating/members/new_member_bookings`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string, studio_id?: number, duration?: (any)[]}) => Promise<import('./data').default['OperatingMemberPagedArrayDefault']>}
   */
  operatingMemberActiveMembers: (params) => {
    return request(`/operating/members/active_members`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string}) => Promise<import('./data').default['OperatingMemberPagedArrayDefault']>}
   */
  operatingMemberInactiveMembers: (params) => {
    return request(`/operating/members/inactive_members`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string}) => Promise<import('./data').default['OperatingMemberPagedArrayDefault']>}
   */
  operatingMemberNotActivatedMembers: (params) => {
    return request(`/operating/members/not_activated_members`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, tag?: string}) => Promise<import('./data').default['OperatingMemberPagedArrayDefault']>}
   */
  operatingMemberProspects: (params) => {
    return request(`/operating/members/prospects`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, expiry_type?: string, tag?: string}) => Promise<import('./data').default['OperatingMemberPagedArrayDefault']>}
   */
  operatingMemberExpiryAlert: (params) => {
    return request(`/operating/members/expiry_alert`, { method: "GET", params })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string}) => Promise<import('./data').default['OperatingMemberPagedArrayDefault']>}
   */
  operatingMemberHallOfFame: (params) => {
    return request(`/operating/members/hall_of_fame`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["OperatingMemberSendCouponsForm"]) => Promise<import('./data').default['any']>}
   */
  operatingMemberSendCoupons: (data) => {
    return request(`/operating/members/send_coupons`, { method: "POST", data })
  },

  /**
   * @type {(data?: definitions["OperatingMemberExtendCouponValidityPeriodForm"]) => Promise<import('./data').default['any']>}
   */
  operatingMemberExtendCouponValidityPeriod: (data) => {
    return request(`/operating/members/extend_coupon_validity_period`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(params: {category?: string, from?: string, to?: string, page?: number, pageSize?: number}) => Promise<import('./data').default['MilestonePagedArrayDefault']>}
   */
  milestoneIndex: (params) => {
    return request(`/operating/milestones`, { method: "GET", params })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['MilestoneDefault']>}
   */
  milestoneShow: (id) => {
    return request(`/operating/milestones/${id}`, { method: "GET" })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, from?: string, to?: string}) => Promise<import('./data').default['MemberMemberPagedArrayDefault']>}
   */
  milestoneBirthdayMembers: (params) => {
    return request(`/operating/milestones/birthday_members`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {page?: number, pageSize?: number, query?: string, orderType?: string, paymentMethod?: string, repurchaseType?: string, coachIds?: (number)[], timeRange?: (string)[]}) => Promise<import('./data').default['OrderPagedArrayDefault']>}
   */
  orderIndex: (params) => {
    return request(`/orders`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["OrderForm"]) => Promise<import('./data').default['any']>}
   */
  orderCreate: (data) => {
    return request(`/orders`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['OrderDefault']>}
   */
  orderShow: (id) => {
    return request(`/orders/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["OrderUpdateForm"]) => Promise<import('./data').default['any']>}
   */
  orderUpdate: (id, data) => {
    return request(`/orders/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  orderRefund: (id) => {
    return request(`/orders/${id}`, { method: "DELETE" })
  },

  /**
   * @type {() => Promise<import('./data').default['PermissionFeatureIncludeChildren'][]>}
   */
  permissionFeatureIndex: () => {
    return request(`/permission/features`, { method: "GET" })
  },

  /**
   * @type {(params: {scene_params?: string}) => Promise<import('./data').default['any']>}
   */
  qrCodeShow: (params) => {
    return request(`/qr_codes`, { method: "GET", params })
  },

  /**
         * @type {(data?: {
"scene_params"?: { [key: string]: any };

}) => Promise<import('./data').default['any']>}
         */
  qrCodeCreate: (data) => {
    return request(`/qr_codes`, { method: "POST", data })
  },

  /**
   * @type {(scene: string) => Promise<import('./data').default['any']>}
   */
  qrCodeDestroy: (scene) => {
    return request(`/qr_codes/${scene}`, { method: "DELETE" })
  },

  /**
         * @type {(data?: {
"page"?: string;
"params"?: { [key: string]: any };
"width"?: number;

}) => Promise<import('./data').default['any']>}
         */
  qrCodeLimtedCode: (data) => {
    return request(`/limited_qr_code`, { method: "POST", data })
  },

  /**
         * @type {(data?: {
"page"?: string;
"params"?: { [key: string]: any };
"width"?: number;

}) => Promise<import('./data').default['any']>}
         */
  qrCodeUnlimtedCode: (data) => {
    return request(`/unlimited_qr_code`, { method: "POST", data })
  },

  /**
   * @type {(params: {from: string, to: string}) => Promise<import('./data').default['any']>}
   */
  reportFinanceOrdersSummary: (params) => {
    return request(`/report/finance/orders_summary`, { method: "GET", params })
  },

  /**
   * @type {(params: {from: string, to: string}) => Promise<import('./data').default['any']>}
   */
  reportFinanceOrdersReport: (params) => {
    return request(`/report/finance/orders_report`, { method: "GET", params })
  },

  /**
   * @type {(params: {from: string, to: string}) => Promise<import('./data').default['any']>}
   */
  reportFinanceExportOrders: (params) => {
    return request(`/report/finance/export_orders`, { method: "GET", params })
  },

  /**
   * @type {(params: {from: string, to: string, coach_level_id: number, query: string}) => Promise<import('./data').default['any']>}
   */
  reportFinanceCoachSalesSummary: (params) => {
    return request(`/report/finance/coach_sales_summary`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {from: string, to: string, coach_level_id: number, query: string}) => Promise<import('./data').default['any']>}
   */
  reportFinanceCoachSalesReport: (params) => {
    return request(`/report/finance/coach_sales_report`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {from: string, to: string, coach_level_id: number, query: string}) => Promise<import('./data').default['any']>}
   */
  reportFinanceExportCoachSales: (params) => {
    return request(`/report/finance/export_coach_sales`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {from: string, to: string, studioId?: number}) => Promise<import('./data').default['any']>}
   */
  reportFinanceCoachCoursesReport: (params) => {
    return request(`/report/finance/coach_courses_report`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {from: string, to: string}) => Promise<import('./data').default['any']>}
   */
  reportFinanceExportCoachCourses: (params) => {
    return request(`/report/finance/export_coach_courses`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {from: string, to: string, productType?: string}) => Promise<import('./data').default['any']>}
   */
  reportFinanceStudioRevenueSummary: (params) => {
    return request(`/report/finance/studio_revenue_summary`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {from: string, to: string, productType?: string}) => Promise<import('./data').default['any']>}
   */
  reportFinanceStudioRevenueReport: (params) => {
    return request(`/report/finance/studio_revenue_report`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(params: {from: string, to: string}) => Promise<import('./data').default['any']>}
   */
  reportFinanceExportStudioRevenue: (params) => {
    return request(`/report/finance/export_studio_revenue`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  reportFinanceDebtsSummary: () => {
    return request(`/report/finance/debts_summary`, { method: "GET" })
  },

  /**
   * @type {(params: {productType?: string}) => Promise<import('./data').default['any']>}
   */
  reportFinanceDebtsReport: (params) => {
    return request(`/report/finance/debts_report`, { method: "GET", params })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  reportFinanceCouponDebtsReport: () => {
    return request(`/report/finance/coupon_debts_report`, { method: "GET" })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  reportFinanceExportDebts: () => {
    return request(`/report/finance/export_debts`, { method: "GET" })
  },

  /**
   * @type {(zoneId: number, studioId: number) => Promise<import('./data').default['StudioBlockPagedArrayBrief']>}
   */
  studioBlockIndex: (zoneId, studioId) => {
    return request(`/studios/${studioId}/zones/${zoneId}/blocks`, {
      method: "GET",
    })
  },

  /**
   * @type {(zoneId: number, studioId: number, data?: definitions["StudioBlockForm"]) => Promise<import('./data').default['any']>}
   */
  studioBlockCreate: (zoneId, studioId, data) => {
    return request(`/studios/${studioId}/zones/${zoneId}/blocks`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(id: number, zoneId: number, studioId: number) => Promise<import('./data').default['StudioBlockDefault']>}
   */
  studioBlockShow: (id, zoneId, studioId) => {
    return request(`/studios/${studioId}/zones/${zoneId}/blocks/${id}`, {
      method: "GET",
    })
  },

  /**
   * @type {(id: number, zoneId: number, studioId: number, data?: definitions["StudioBlockForm"]) => Promise<import('./data').default['any']>}
   */
  studioBlockUpdate: (id, zoneId, studioId, data) => {
    return request(`/studios/${studioId}/zones/${zoneId}/blocks/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(zoneId: number, studioId: number, id: number) => Promise<import('./data').default['any']>}
   */
  studioBlockDestroy: (zoneId, studioId, id) => {
    return request(`/studios/${studioId}/zones/${zoneId}/blocks/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {(studioId: number, params: {zoneId?: number, active?: boolean}) => Promise<import('./data').default['StudioHrSensorPagedArrayDefault']>}
   */
  studioHrSensorIndex: (studioId, params) => {
    return request(`/studios/${studioId}/hr_sensors`, { method: "GET", params })
  },

  /**
   * @type {(studioId: number, data?: definitions["StudioHrSensorForm"]) => Promise<import('./data').default['any']>}
   */
  studioHrSensorCreate: (studioId, data) => {
    return request(`/studios/${studioId}/hr_sensors`, { method: "POST", data })
  },

  /**
   * @type {(studioId: number, id: number) => Promise<import('./data').default['StudioHrSensorDefault']>}
   */
  studioHrSensorShow: (studioId, id) => {
    return request(`/studios/${studioId}/hr_sensors/${id}`, { method: "GET" })
  },

  /**
   * @type {(studioId: number, id: number, data?: definitions["StudioHrSensorForm"]) => Promise<import('./data').default['any']>}
   */
  studioHrSensorUpdate: (studioId, id, data) => {
    return request(`/studios/${studioId}/hr_sensors/${id}`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(studioId: number, id: number) => Promise<import('./data').default['any']>}
   */
  studioHrSensorDestroy: (studioId, id) => {
    return request(`/studios/${studioId}/hr_sensors/${id}`, {
      method: "DELETE",
    })
  },

  /**
   * @type {(studioId: number) => Promise<import('./data').default['AttachmentPagedArrayDefault']>}
   */
  studioImageIndex: (studioId) => {
    return request(`/studios/${studioId}/images`, { method: "GET" })
  },

  /**
   * @type {(studioId: number, data?: definitions["AttachmentForm"]) => Promise<import('./data').default['any']>}
   */
  studioImageCreate: (studioId, data) => {
    return request(`/studios/${studioId}/images`, { method: "POST", data })
  },

  /**
   * @type {(studioId: number, id: number) => Promise<import('./data').default['any']>}
   */
  studioImageDestroy: (studioId, id) => {
    return request(`/studios/${studioId}/images/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(studioId: number, id: number) => Promise<import('./data').default['any']>}
   */
  studioImagePrimary: (studioId, id) => {
    return request(`/studios/${studioId}/images/${id}/primary`, {
      method: "PUT",
    })
  },

  /**
   * @type {(params: {granted_only?: boolean, active?: boolean}) => Promise<import('./data').default['StudioStudioPagedArrayDefault']>}
   */
  studioStudioIndex: (params) => {
    return request(`/studios`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["StudioStudioForm"]) => Promise<import('./data').default['any']>}
   */
  studioStudioCreate: (data) => {
    return request(`/studios`, { method: "POST", data })
  },

  /**
   * @type {(params: {granted_only?: boolean}) => Promise<import('./data').default['StudioStudioList'][]>}
   */
  studioStudioList: (params) => {
    return request(`/studios/list`, { method: "GET", params })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['StudioStudioDefault']>}
   */
  studioStudioShow: (id) => {
    return request(`/studios/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["StudioStudioForm"]) => Promise<import('./data').default['any']>}
   */
  studioStudioUpdate: (id, data) => {
    return request(`/studios/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  studioStudioDestroy: (id) => {
    return request(`/studios/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['StudioStudioOpenGates']>}
   */
  studioStudioOpenGates: (id) => {
    return request(`/studios/${id}/open_gates`, { method: "POST" })
  },

  /**
   * @type {(studioId: number, params: {active?: boolean}) => Promise<import('./data').default['StudioZonePagedArrayDefault']>}
   */
  studioZoneIndex: (studioId, params) => {
    return request(`/studios/${studioId}/zones`, { method: "GET", params })
  },

  /**
   * @type {(studioId: number, data?: definitions["StudioZoneForm"]) => Promise<import('./data').default['any']>}
   */
  studioZoneCreate: (studioId, data) => {
    return request(`/studios/${studioId}/zones`, { method: "POST", data })
  },

  /**
   * @type {(params: {id?: number, page?: number, pageSize?: number}) => Promise<import('./data').default['StudioZonePagedArrayList']>}
   */
  studioZoneListwithoutstudioid: (params) => {
    return request(`/zones/list`, { method: "GET", params })
  },

  /**
   * @type {(studioId: number, params: {id?: number, page?: number, pageSize?: number}) => Promise<import('./data').default['StudioZonePagedArrayList']>}
   */
  studioZoneList: (studioId, params) => {
    return request(`/studios/${studioId}/zones/list`, { method: "GET", params })
  },

  /**
   * @type {(studioId: number, id: number) => Promise<import('./data').default['StudioZoneDefault']>}
   */
  studioZoneShow: (studioId, id) => {
    return request(`/studios/${studioId}/zones/${id}`, { method: "GET" })
  },

  /**
   * @type {(studioId: number, id: number, data?: definitions["StudioZoneForm"]) => Promise<import('./data').default['any']>}
   */
  studioZoneUpdate: (studioId, id, data) => {
    return request(`/studios/${studioId}/zones/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(studioId: number, id: number) => Promise<import('./data').default['any']>}
   */
  studioZoneDestroy: (studioId, id) => {
    return request(`/studios/${studioId}/zones/${id}`, { method: "DELETE" })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasBigCircleDetail']>}
   */
  tasBigCircleShow: (id) => {
    return request(`/tas/big_circles/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasBigCircleForm"]) => Promise<import('./data').default['any']>}
   */
  tasBigCircleUpdate: (id, data) => {
    return request(`/tas/big_circles/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasScreenPracticesWithScreen'][]>}
   */
  tasBigCircleScreens: (id) => {
    return request(`/tas/big_circles/${id}/screens`, { method: "GET" })
  },

  /**
         * @type {(id: number, data?: {
"exercise_pattern"?: number;
"exercise_id"?: number;

}) => Promise<import('./data').default['any']>}
         */
  tasBigCircleUpdatePractices: (id, data) => {
    return request(`/tas/big_circles/${id}/update_practices`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {() => Promise<import('./data').default['TasEquipmentDefault'][]>}
   */
  tasEquipmentIndex: () => {
    return request(`/tas/equipment`, { method: "GET" })
  },

  /**
   * @type {(data?: definitions["TasEquipmentForm"]) => Promise<import('./data').default['any']>}
   */
  tasEquipmentCreate: (data) => {
    return request(`/tas/equipment`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasEquipmentDetail']>}
   */
  tasEquipmentShow: (id) => {
    return request(`/tas/equipment/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasEquipmentForm"]) => Promise<import('./data').default['any']>}
   */
  tasEquipmentUpdate: (id, data) => {
    return request(`/tas/equipment/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(params: {query?: string, owner?: string, primaryEquipmentId?: number, active?: boolean}) => Promise<import('./data').default['PlatformTasExercisePagedArrayDefault']>}
   */
  tasExerciseIndex: (params) => {
    return request(`/tas/exercises`, { method: "GET", params })
  },

  /**
   * @type {(data?: definitions["PlatformTasExerciseForm"]) => Promise<import('./data').default['any']>}
   */
  tasExerciseCreate: (data) => {
    return request(`/tas/exercises`, { method: "POST", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['PlatformTasExerciseDetail']>}
   */
  tasExerciseShow: (id) => {
    return request(`/tas/exercises/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["PlatformTasExerciseForm"]) => Promise<import('./data').default['any']>}
   */
  tasExerciseUpdate: (id, data) => {
    return request(`/tas/exercises/${id}`, { method: "PUT", data })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasExerciseDestroy: (id) => {
    return request(`/tas/exercises/${id}`, { method: "DELETE" })
  },

  /**
   * @type {() => Promise<import('./data').default['any']>}
   */
  tasExerciseTags: () => {
    return request(`/tas/exercises/tags`, { method: "GET" })
  },

  /**
   * @type {(params: {productId?: number}) => Promise<import('./data').default['TasPackageEssential'][]>}
   */
  tasPackageList: (params) => {
    return request(`/tas/packages/list`, { method: "GET", params })
  },

  /**
   * @type {(workoutId: number, params: {page?: number, pageSize?: number}) => Promise<import('./data').default['TasPackagePagedArrayEssential']>}
   */
  tasPackageIndex: (workoutId, params) => {
    return request(`/tas/workouts/${workoutId}/packages`, {
      method: "GET",
      params,
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['TasPackageDefault']>}
   */
  tasPackageShow: (id) => {
    return request(`/tas/packages/${id}`, { method: "GET" })
  },

  /**
   * @type {(id: number, data?: definitions["TasPracticeForm"]) => Promise<import('./data').default['any']>}
   */
  tasScreenUpdatePractices: (id, data) => {
    return request(`/tas/screens/${id}/update_practices`, {
      method: "PUT",
      data,
    })
  },

  /**
   * @type {(params: {query?: string, productId: number, workflowState?: string, owner?: string, page?: number, pageSize?: number}) => Promise<import('./data').default['TasWorkoutOutlinePagedArrayEssential']>}
   */
  tasWorkoutOutlineIndex: (params) => {
    return request(`/tas/workout_outlines`, { method: "GET", params })
  },

  /**
   * @type {(params: {query?: string, productId: number, packageId?: number, state?: string, id?: number, page?: number, pageSize?: number}) => Promise<import('./data').default['TasWorkoutOutlinePagedArrayList']>}
   */
  tasWorkoutOutlineList: (params) => {
    return request(`/tas/workout_outlines/list`, { method: "GET", params })
  },

  /**
   * @type {(workoutOutlineId: number, data?: definitions["TasWorkoutOutlineCreateForm"]) => Promise<import('./data').default['TasWorkoutOutlineBrief']>}
   */
  tasWorkoutOutlineCopy: (workoutOutlineId, data) => {
    return request(`/tas/workout_outlines/${workoutOutlineId}/copy`, {
      method: "POST",
      data,
    })
  },

  /**
   * @type {(workoutOutlineId: number) => Promise<import('./data').default['TasWorkoutOutlineDetail']>}
   */
  tasWorkoutOutlineShow: (workoutOutlineId) => {
    return request(`/tas/workout_outlines/${workoutOutlineId}`, {
      method: "GET",
    })
  },

  /**
   * @type {(workoutOutlineId: number, data?: definitions["TasWorkoutOutlineEditForm"]) => Promise<import('./data').default['TasWorkoutOutlineDefault']>}
   */
  tasWorkoutOutlineUpdate: (workoutOutlineId, data) => {
    return request(`/tas/workout_outlines/${workoutOutlineId}`, {
      method: "PATCH",
      data,
    })
  },

  /**
   * @type {(workoutOutlineId: number) => Promise<import('./data').default['TasWorkoutOutlineWithExercises']>}
   */
  tasWorkoutOutlineExercises: (workoutOutlineId) => {
    return request(`/tas/workout_outlines/${workoutOutlineId}/exercises`, {
      method: "GET",
    })
  },

  /**
   * @type {(workoutOutlineId: number) => Promise<import('./data').default['TasTimelineTas']>}
   */
  tasWorkoutOutlineTimeline: (workoutOutlineId) => {
    return request(`/tas/workout_outlines/${workoutOutlineId}/timeline`, {
      method: "GET",
    })
  },

  /**
   * @type {(workoutOutlineId: number) => Promise<import('./data').default['TasWorkoutOutlineWithPlaylistItems']>}
   */
  tasWorkoutOutlinePlaylistItems: (workoutOutlineId) => {
    return request(`/tas/workout_outlines/${workoutOutlineId}/playlist_items`, {
      method: "GET",
    })
  },

  /**
   * @type {(id: number) => Promise<import('./data').default['any']>}
   */
  tasWorkoutOutlineRefreshPlaylist: (id) => {
    return request(`/tas/workout_outlines/${id}/refresh_playlist`, {
      method: "PUT",
    })
  },

  /**
   * @type {(workoutOutlineId: number, data?: definitions["TasWorkoutOutlineUpdateFrameForm"]) => Promise<import('./data').default['any']>}
   */
  tasWorkoutOutlineUpdateFrame: (workoutOutlineId, data) => {
    return request(`/tas/workout_outlines/${workoutOutlineId}/update_frame`, {
      method: "PATCH",
      data,
    })
  },

  /**
   * @type {(params: {id?: number}) => Promise<import('./data').default['TasWorkoutEssential'][]>}
   */
  tasWorkoutList: (params) => {
    return request(`/tas/workouts/list`, { method: "GET", params })
  },

  /**
   * @type {(workoutId: number) => Promise<import('./data').default['TasWorkoutEssential'][]>}
   */
  tasWorkoutShow: (workoutId) => {
    return request(`/tas/workouts/${workoutId}`, { method: "GET" })
  },

  /**
   * @type {(params: {query?: string}) => Promise<import('./data').default['UserDefault'][]>}
   */
  userIndex: (params) => {
    return request(`/users`, { method: "GET", params })
  },
}
