<template>
  <el-form ref="form" size="small" class="mf-form" v-bind='formConfig' :model='actualForm'>
    <template v-for="(item, i) in itemConfig" v-if="item.renderIf ? item.renderIf(actualForm) : true">
      <el-form-item v-if="item.slot" :key='"form-item" + i' :label="item.label" :prop='item.slot' :error='error[item.slot]'>
        <slot :name="item.slot"></slot>
      </el-form-item>

      <el-form-item v-else-if="item.component" :key='"form-item" + i' :label="item.label" :prop='item.prop'
                    :error='error[item.prop]'>
        <component :is="item.component" v-model='form[item.prop]' v-bind="item.propsData">
          <template slot="prepend" v-if="$get(item, 'propsData.prepend')">{{$get(item, 'propsData.prepend')}}</template>
          <template slot="append" v-if="$get(item, 'propsData.append')">{{$get(item, 'propsData.append')}}</template>
        </component>
      </el-form-item>

      <el-form-item :label='item.label' :prop='item.prop' :key='"form-item" + i' :error='error[item.prop]' v-else>
        <el-input v-if="item.itemType === 'input'" v-model="form[item.prop]" v-bind="item.propsData" />

        <template v-if="item.itemType === 'select'">
          <el-select v-model='form[item.prop]' v-bind="item.propsData">
            <el-option v-for="option in item.propsData.data" :key="option[item.valueKey || 'value']"
                      :label="option[item.labelKey || 'label']" :value="option[item.valueKey || 'value']"></el-option>
          </el-select>
        </template>

        <mf-form-list :config='item.config' v-model="form[item.prop]" v-if="item.itemType === 'list'" v-bind="item.propsData" />
      </el-form-item>

      <div v-if='item.tips' :style='{marginLeft: formConfig.labelWidth}' :key='"tips" + i'>
        <div class='tips' v-if='isString(item.tips)'>{{item.tips}}</div>
        <div class="tips" v-if="isFunction(item.tips)">{{item.tips(actualForm)}}</div>
        <slot :name='item.tips.slot' v-else></slot>
      </div>
    </template>

    <div class="submit-wrapper">
      <slot name="action-btn"></slot>
      <el-button v-if="config.submitText !== null" class="btn-submit" type='primary'
                 :style='{marginLeft: formConfig.labelWidth || "120px"}' :loading='actualLoading' @click="handleSubmit">
        {{actualLoading ? loadingText : submitText}}</el-button>
    </div>
  </el-form>
</template>

<script>
import Gender from '@/components/filter/Gender'
import Studios from '@/components/filter/Studios'
import {
  isEmpty,
  isString,
  isArray,
  cloneDeep,
  isFunction,
  isObject,
} from 'lodash'

export default {
  name: 'MfForm',

  components: {
    Gender,
    Studios,
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
    submitLoading: Boolean,
    parentData: Object,
    submitFunc: Function,
  },

  data() {
    let form = this.createFormData()
    return {
      form,
      isLoading: false,
      error: {},
    }
  },

  computed: {
    actualForm() {
      return {
        ...this.form,
        ...this.parentData,
      }
    },

    actualConfig() {
      let config = cloneDeep(this.config)
      for (const key in config.itemConfig) {
        if (config.itemConfig.hasOwnProperty(key)) {
          const item = config.itemConfig[key]
          if (item.hasOwnProperty('required') && item.required) {
            let prop = item.prop || item.slot
            let rule = [
              {
                required: true,
                trigger: 'blur',
                message: `${item.label}不能为空`,
              },
            ]
            if (config.hasOwnProperty('rules')) {
              if (!config.rules.hasOwnProperty(item.prop)) {
                config.rules[prop] = rule
              }
            } else {
              config.rules = {}
              config.rules[prop] = rule
            }
          }
        }
      }
      config.itemConfig.forEach((item) => {
        if (!item.slot && !item.component && !item.itemType) {
          item.component = 'el-input'
        }
      })
      return config
    },

    itemConfig() {
      return this.actualConfig.itemConfig
    },

    loadingText() {
      return this.actualConfig.loadingText || '提交中...'
    },

    submitText() {
      return this.actualConfig.submitText || '保存'
    },

    actualLoading() {
      if (!this.submitFunc) {
        return this.submitLoading
      }
      return this.isLoading
    },

    formConfig() {
      let { itemConfig, ...other } = this.actualConfig
      if (!other.labelWidth) {
        other.labelWidth = '120px'
      }
      return { ...other, ...this.$attrs }
    },

    dialogVisible() {
      return this.$store.state.global.activeDialog
    },
  },

  watch: {
    config: {
      handler: function (obj) {
        this.form = this.createFormData()
      },
      deep: true,
    },

    dialogVisible(val) {
      this.clearError()
      this.$refs.form.clearValidate()
    },
  },

  methods: {
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isLoading = true
          this.clearError()
          if (this.submitFunc) {
            let data = cloneDeep(this.actualForm)
            this.itemConfig.forEach((item) => {
              // 过滤掉renderIf为false的值
              if (item.renderIf && !item.renderIf(data)) {
                let prop = item.prop || item.slot
                delete data[prop]
              }
            })
            data._errorMessage = 'form' // 显示表单的错误提示
            let pr = null
            let cb = () => this.$router.go(-1) // 默认回调为返回上一页
            let msg = '保存成功'
            let result = this.submitFunc(data)
            if (result === undefined) {
              throw Error('submitFunc没有返回任何值')
            }
            if (result instanceof Promise) {
              pr = result
            } else {
              pr = result.pr
              cb = result.cb
              msg = result.msg || '保存成功'
              if (!pr instanceof Promise || typeof cb !== 'function') {
                throw Error('submitFunc返回的参数值有误')
              }
            }
            if (pr) {
              pr.then((res) => {
                if (result.msg !== null) {
                  this.$message({
                    message: msg,
                    type: 'success',
                  })
                }
                cb(res)
              })
                .catch((err) => {
                  console.log(err)
                  let { data } = err.response
                  for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                      let value = data[key]
                      let item = this.itemConfig.find(
                        (item) => item.prop === key || item.slot === key
                      )
                      let label = item ? item.label : ''
                      value = value.map((item) => label + item)
                      this.$set(this.error, key, value.join('，'))
                    }
                  }
                })
                .finally((_) => {
                  this.isLoading = false
                })
            } else {
              cb()
              this.isLoading = false
            }
          } else {
            this.$emit('submit', { ...this.actualForm })
          }
        }
      })
    },

    createFormData() {
      let config = this.config.itemConfig
      let form = {}
      config.forEach((obj) => {
        if (!obj.slot) {
          let value =
            obj.defaultValue !== null ? cloneDeep(obj.defaultValue) : null
          if (obj.itemType === 'select' && !obj.defaultValue) {
            form[obj.prop] = []
          } else {
            form[obj.prop] = value
          }
        }
      })
      return form
    },

    isString,

    isFunction,

    isObject,

    reset() {
      this.$refs.form.resetFields()
      for (const key in this.form) {
        if (this.form.hasOwnProperty(key)) {
          this.form[key] = ''
        }
      }
    },

    clearError() {
      this.error = {}
    },
  },
}
</script>

<style lang='scss'>
.mf-form {
  padding: 40px;

  .el-form-item:last-of-type {
    margin-bottom: 0;
  }

  .el-form-item__label {
    color: #5f6265;
    padding-right: 16px;
  }

  .el-input,
  .el-textarea {
    width: 400px;
  }

  .tips {
    font-size: 12px;
    margin-top: -20px;
    margin-bottom: 15px;
    color: rgba(#000, 0.25);
    line-height: 1.666666667;

    &:before {
      content: '*';
    }
  }

  .el-form-item + .submit-wrapper {
    * + * {
      margin-left: 32px !important;
    }

    .el-button {
      display: block;
      padding: 9px 0;
      width: 130px;
    }

    .btn-submit {
      color: #fff;
      background-color: #409eff;
      border-color: #409eff;

      &:active {
        background: #3a8ee6;
        border-color: #3a8ee6;
      }
    }
  }
}
</style>
