import Layout from 'views/layout/Layout'
import RouterView from 'views/layout/RouterView'


export default {
  path: '/membership',
  redirect: '/membership/order',
  name: 'membership',
  component: Layout,
  title: '订单管理',
  hidden: false,
  icon: 'card',
  permission: ['order'],
  children: [
    {
      path: 'order',
      component: () => import('views/membership/order/Detail'),
      name: 'membershipOrderDetail',
      title: '订单列表',
      hidden: false,
      permission: ['order', 'list'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/hneduo"
    },
    {
      path: 'prepay_card',
      component: () => import('views/membership/prepaycard/List'),
      name: 'prepayCard',
      title: '储值卡配置',
      hidden: false,
      permission: ['order', 'prepay_card'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/udy3xn",
    },
    {
      path: 'card_options/:id/card_option_coupons',
      name: 'cardOptionCoupons',
      component: () => import('views/membership/prepaycard/CardOptionCouponIndex'),
      title: '储值卡赠券管理',
      hidden: true,
      permission: ['order', 'prepay_card'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/udy3xn"
    },
    {
      path: 'membership_cards',
      component: () => import('views/membership/card/List'),
      name: 'membershipCardList',
      title: '会员卡配置',
      hidden: false,
      permission: ['order', 'membership_cards'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/li2s6r"
    },
    {
      path: 'coupons',
      redirect: '/membership/coupons/list',
      name: 'coupons',
      component: RouterView,
      title: '代金券配置',
      hidden: false,
      permission: ['order', 'coupons'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/qnmeoy",
      children: [
        {
          path: 'list',
          component: () => import('views/membership/coupon/Index'),
          name: 'couponList',
          title: '代金券列表',
        },
        {
          path: ':id/event_coupon',
          component: () => import('views/membership/coupon/components/CouponEventCouponIndex'),
          title: '代金券营销详情',
          name: 'CouponEventCouponIndex',
        }
      ]
    },
    {
      path: 'refund',
      component: () => import('views/membership/order/Detail?tab=refund'),
      name: 'membershipRefundDetail',
      title: '退款列表',
      permission: ['order', 'refund'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/hneduo"
    },
    // {
    //   path: 'refund/add',
    //   component: () => import('views/membership/order/RefundAdd'),
    //   name: 'membershipOrderRefundAdd',
    //   title: '创建退款',
    //   // permission: ['order', 'refund'],
    // },
    {
      path: 'card/:cardId/detail',
      component: () => import('views/membership/card/Detail'),
      name: 'membershipCardDetail',
      title: '会员卡详情',
      permission: ['order', 'list'],
      helpDoc: "https://www.yuque.com/ap1q4q/ngd5zk/li2s6r"
    },
    // {
    //   path: 'new',
    //   component: () => import('views/membership/card/MembershipCardNew'),
    //   name: 'membershipMembershipCardNew',
    //   title: '新建卡种',
    //   permission: ['order', 'list'],
    // },
    // {
    //   path: ':cardId/edit',
    //   component: () => import('views/membership/card/MembershipCardEdit'),
    //   name: 'membershipMembershipCardEdit',
    //   title: '编辑卡种',
    //   permission: ['order', 'list'],
    // },
    // {
    //   path: 'card/:cardId/new',
    //   component: () => import('views/membership/card/New'),
    //   name: 'membershipCardNew',
    //   title: '新建会员卡',
    //   permission: ['order', 'list'],
    // },
    // {
    //   path: 'card/:cardId/:optionId/edit',
    //   component: () => import('views/membership/card/Edit'),
    //   name: 'membershipCardEdit',
    //   title: '编辑会员卡',
    //   permission: ['order', 'list'],
    // },
  ],
}
